<script>
import axios from 'axios';
import BackButton from '@/components/BackButton.vue';
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';

export default {
  name: 'ClientContracts',
  components: { BackButton },
  data: () => ({
    editing: false,
    client: {
      loading: true,
      loaded: false,
      id: null,
      data: {},
    },
    contract: {
      loading: false,
      saving: false,
      data: {
        id: null,
        type: null,
        start_date: null,
        end_date: null,
        file: null,
      },
    },
    type_options: [
      { text: 'Contract', value: 'contract' },
      { text: 'Amendment', value: 'amendment' },
    ],
    status_options: [
      { text: 'Active', value: 'active' },
      { text: 'Inactive', value: 'inactive' },
    ],
  }),
  beforeMount() {
    this.client.id = this.$route.params.clientID;
    this.fetchClient();
    if (this.$route.params.contractID) {
      this.editing = true;
      this.contract.data.id = this.$route.params.contractID;
      this.fetchContract();
    }
  },
  validations() {
    return {
      contract: {
        data: {
          type: { required },
          start_date: { required },
          end_date: { required, greaterThanStart: value => this.validateEndDate(value) },
          file: { required, pdf: value => this.validateFile(value) },
        },
      },
    };
  },
  mixins: [ errorHandler ],
  computed: {
    overlay() {
      if (this.client.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching client',
        };
      }
      if (this.contract.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching contract',
        };
      }
      if (this.contract.saving) {
        return {
          show: true,
          icon: 'save',
          animation: 'throb',
          text: 'Saving contract',
        };
      }

      return {
        show: false,
        icon: '',
        animation: '',
        text: '',
      };
    },
    disabled() {
      return this.editing || this.contract.saving;
    },
  },
  methods: {
    fetchClient() {
      this.client.loading = true;
      return axios.get(`v1/clients/${this.client.id}`, { params: { by: 'id' } })
        .then(response => {
          this.client.data = response.data.client;
          this.client.loaded = true;
        })
        .catch(e => {
          this.$noty.error('Error loading client');
          console.error(e);
        })
        .finally(() => {
          this.client.loading = false;
        });
    },
    fetchContract() {
      const { clientID, contractID } = this.$route.params;

      this.contract.loading = true;
      return axios.get(`v1/clients/${clientID}/contracts/${contractID}`, {})
        .then(response => {
          this.contract.data.id = response.data.id;
          this.contract.data.type = response.data.type;
          this.contract.data.start_date = new Date(response.data.start_date);
          this.contract.data.end_date = new Date(response.data.end_date);
          this.contract.data.file = null;
        })
        .catch(err => {
          this.$noty.error('An error occurred while fetching contract');
          console.error('failed to fetch contract:', err);
        })
        .finally(() => {
          this.contract.loading = false;
        });
    },
    openConfirmationModal() {
      if (!this.validateForm()) {
        return null;
      }

      return this.$bvModal.msgBoxConfirm('Are you sure you want to save?', { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return;
          }

          this.addContract();
        })
        .catch(() => {
          this.$noty.error('Cannot open confirmation dialog');
        });
    },
    addContract() {
      const formData = new FormData();
      formData.append('client_id', this.client.id);
      formData.append('type', this.contract.data.type);
      formData.append('start_date', new Date(this.contract.data.start_date).toISOString());
      formData.append('end_date', new Date(this.contract.data.end_date).toISOString());
      formData.append('file', this.contract.data.file);

      this.contract.saving = true;
      return axios.post(`v1/clients/${this.client.id}/contracts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.$noty.success('Contract added successfully');
          this.$router.go(-1);
        })
        .catch(err => {
          this.$noty.error('An error occurred while saving contract');
          console.error(`Failed to save contract: ${err}`);
        })
        .finally(() => {
          this.contract.saving = false;
        });
    },
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    validateFile(file) {
      if (!file) return true;
      return file.name.toLowerCase().endsWith('.pdf');
    },
    validateEndDate(date) {
      if (!date || !this.contract.data.start_date) {
        return true;
      }

      const startDate = new Date(this.contract.data.start_date);
      const endDate = new Date(date);

      return endDate > startDate;
    },
  },
};
</script>

<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center">
          <back-button />
          <h3 class="ml-2" v-if="editing">Editing contract for {{ client.data.display_name }}</h3>
          <h3 class="ml-2" v-else>New contract for {{ client.data.display_name }}</h3>
        </div>
      </b-col>
    </b-row>
    <b-overlay class="mt-3" :show="overlay.show">
      <template #overlay>
        <div class="text-center">
          <b-icon :animation="overlay.animation" :icon="overlay.icon" font-scale="3"></b-icon>
          <p id="cancel-label">{{ overlay.text }}</p>
        </div>
      </template>
      <b-card>
        <b-card-text>
          <b-form v-if="contract.data" @submit.prevent="openConfirmationModal">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Contract type"
                              v-slot="{ ariaDescribedby }"
                              :state="isValid('contract.data.type')"
                              :invalid-feedback="$v.contract.data.type.$dirty ? 'A contract type is required.': ''">
                  <b-form-radio-group
                    v-model="contract.data.type"
                    :options="type_options"
                    :disabled="disabled"
                    :aria-describedby="ariaDescribedby" name="selection_type" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <b-form-group label="Start date"
                              v-slot="{ ariaDescribedby }"
                              :state="isValid('contract.data.start_date')"
                              :invalid-feedback="$v.contract.data.start_date.$dirty ? 'A start date is required.': ''">
                  <b-form-datepicker
                    v-model="contract.data.start_date"
                    :disabled="disabled"
                    required />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="End date"
                              v-slot="{ ariaDescribedby }"
                              :state="isValid('contract.data.end_date')"
                              :invalid-feedback="$v.contract.data.end_date.$dirty ?
                              'A end date is required and must be greater than start date.': ''">
                  <b-form-datepicker id="dpEndDate" v-model="contract.data.end_date"
                                     :disabled="disabled" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="File" v-slot="{ ariaDescribedby }"
                              :state="isValid('contract.data.file')"
                              :invalid-feedback="$v.contract.data.file.$dirty ?
                              'A contract/amendment file is required and must be .pdf format.': ''">
                  <b-form-file v-model="contract.data.file"
                               accept="application/pdf"
                               :disabled="disabled"
                               placeholder="Choose a file or drop it here..."
                               drop-placeholder="Drop file here..." />
                  <b-form-text>Allowed formats: pdf</b-form-text>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="6">
                <b-button type="submit" variant="primary" class="float-right" :disabled="disabled">Save</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-card>
    </b-overlay>
  </b-container>
</template>
